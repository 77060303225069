<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    :title="!category ? 'Thêm mới danh mục' : 'Chỉnh sửa danh mục'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Tên danh mục" prop="name">
        <el-input v-model="form.name" placeholder="Nhập vào tên danh mục"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateCategory">{{ !category ? 'Tạo' : 'Cập nhật' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createCategory, updateCategory } from '@/services/category'
export default {
  name: 'UpdateCategory',
  props: {
    category: Object,
    visible: Boolean,
    type: String,
    placeTypes: Array
  },
  data() {
    return {
      callingAPI: false,
      form: {
        name: this.category ? this.category.name : ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên danh mục không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    dataInput() {
      const data = {
        name: this.form.name,
        locale: this.language
      }
      if (!['tour', 'post'].includes(this.type)) {
        data.place_type = this.type
      }
      if (this.category) {
        data.id = this.category.id
      }
      return data
    }
  },
  methods: {
    handleUpdateCategory() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.category) {
            createCategory(this.dataInput, this.type).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Tạo mới danh mục thành công'
              })
              this.$emit('reload')
              this.$emit('reload-constant')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          } else {
            updateCategory(this.dataInput, this.type).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Cập nhật danh mục thành công'
              })
              this.$emit('reload')
              this.$emit('reload-constant')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          }
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
